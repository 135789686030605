import * as THREE from 'three'
import Experience from './Experience.js'
import MathUtil from './Utils/MathUtil.js'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class MainSphere
{
    constructor(_options)
    {
        this.experience = new Experience()
        this.config = this.experience.config
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.renderer = this.experience.renderer
        this.math = new MathUtil()

        this.isReady = false
        this.canvas = document.querySelector('.experience')

        this.mediaQuery = window.matchMedia('(max-width: 960px)')

        this.init()
        this.setCamera()
        this.setAnimation()
        this.setScrollAnimation()
    }

    init()
    {
        // 更新でページトップへ
        window.onbeforeunload = () => {  
            window.scrollTo(0, 0)
          }

        // アニメーション完了までスクロールロック
        document.addEventListener('touchmove', this._noScroll, { passive: false })
        document.addEventListener('wheel',this._noScroll, { passive: false })
        window.addEventListener('scroll', this._backScroll )


        this.gltf = this.resources.items.mainSphere

        // this.triangles = []
        // モデルの読み込み
        this.gltf.scene.traverse((child) => {
            if(child.name === 'MainBall')
            {
                this.mainBall = child
            }
            if(child.name === 'SubBall')
            {
                this.subBall = child
            }
            if(child.name === 'ThirdBall')
            {
                this.thirdBall = child
            }
            // マテリアルの設定
            if(child.parent){
                if(child.parent.name.match(/BlueTriangles\w*/)  || child.parent.name === 'BlueBalls')
                {
                    child.material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color( 0x0078CA ),
                        transparent: true,
                        opacity: this.math.random(0.2, 0.5)
                    })
                }
                else if(child.parent.name.match(/RedTriangles\w*/) || child.parent.name === 'RedBalls')
                {
                    child.material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color( 0xCF613F ),
                        transparent: true,
                        opacity: this.math.random(0.2, 0.5)
                    })
                }
                else if(child.parent.name.match(/YellowTriangles\w*/)  || child.parent.name === 'YellowBalls')
                {
                    child.material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color( 0xE1DD4F ),
                        transparent: true,
                        opacity: this.math.random(0.3, 0.6)
                    })
                }
                else if (child.name.match(/RedAtomBall\w*/))
                {
                    child.material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color( 0xCF613F ),
                        transparent: true,
                    })
                }
                else if (child.name.match(/YellowAtomBall\w*/))
                {
                    child.material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color( 0xE1DD4F ),
                        transparent: true,
                    })
                }
                else if (child.name.match(/BlueAtomBall\w*/))
                {
                    child.material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color( 0x0078CA ),
                        transparent: true,
                    })
                }
                else if (child.name.match(/Outline\w*/) || child.name.match(/Connector.\w*/) )
                {
                    child.material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color( 0x000000 ),
                        transparent: true,
                        opacity: 0.19
                    })
                }
                else {
                    child.material = new THREE.MeshBasicMaterial({
                        color: new THREE.Color( 0x000000 ),
                        transparent: true,
                        opacity: 0.13
                    })
                }
            }
        })
        this.scene.add(this.gltf.scene)

        if(this.mediaQuery.matches)
        {
            this.mainBall.scale.set(0.7, 0.7, 0.7)
            this.subBall.scale.set(0.7, 0.7, 0.7)
            this.thirdBall.scale.set(0.7, 0.7, 0.7)
        }

        gsap.fromTo(this.canvas, { autoAlpha: 0}, { autoAlpha: 1, duration: 2, ease: "power1.inOut", 
            onComplete: () => {
                if(this.mediaQuery.matches)
                {
                    setTimeout(() => {
                        document.removeEventListener('touchmove', this._noScroll, { passive: false })
                        window.removeEventListener('scroll', this._backScroll )
                    }, 1500)
                }
        }})
    }

    setCamera()
    {
        this.camera = this.gltf.cameras[0]
        this.camera.aspect = window.innerWidth / window.innerHeight
    }

    setAnimation()
    {
        // // スクロール率の算出
        // this.scrollPercent = 0
        // document.body.onscroll = () =>
        // {
        //     this.scrollPercent = document.documentElement.scrollTop /
        //     (document.documentElement.scrollHeight - document.documentElement.clientHeight)
        // }

        if(this.mediaQuery.matches)
        {
            this.camera.position.set(0, 0, 2127)
            this.camera.rotation.set(0, 0, 0)
            return
        }

        this.animationMixer = new THREE.AnimationMixer(this.camera.parent)
        this.animationClip = this.gltf.animations[0]
        this.animationAction = this.animationMixer.clipAction(this.animationClip)
        this.animationAction.loop = THREE.LoopOnce
        this.animationAction.clampWhenFinished = true
        this.animationMixer.addEventListener('finished', () => {
            document.removeEventListener('touchmove', this._noScroll, { passive: false })
            document.removeEventListener('wheel', this._noScroll, { passive: false });
            window.removeEventListener('scroll', this._backScroll )
            this.isReady = true
            this.camera.position.set(this.camera.position.x, this.camera.position.y, this.camera.position.z)
        })
        console.log(document.readyState)

        if(document.readyState === 'complete') {
            setTimeout(() => {
                this.animationAction.play();
            }, 2000)
        } else {
            window.addEventListener('load', () => {
                setTimeout(() => {
                    this.animationAction.play();
                }, 2000)
            })
        }
    }

    setScrollAnimation() {
        const leftTrigger = document.querySelector('.sphere-to-left')
        const centerTrigger = document.querySelector('.sphere-to-center')
        ScrollTrigger.defaults({
            immediateRender: false,
            ease: "power1.inOut",
            toggleActions: "play none none reverse"
          })

        let tlLeft = gsap.timeline({
            scrollTrigger: {
                id: 'toLeft',
                trigger: leftTrigger,
                start: "top bottom",
                end: "center-=20% bottom",
                scrub: 1,
                preventOverlaps: true,
            }
        })

        if(this.mediaQuery.matches)
        {
            tlLeft.to(this.camera.position, {x: 1000, z: 0, ease: "power1.inOut"}, 'toLeft')
            .to(this.camera.rotation, {y: Math.PI / 3, ease: "power1.inOut"}, 'toLeft')
            .to(this.canvas, { autoAlpha: 0.3}, 'toLeft')
        } else (
            tlLeft.to(this.camera.position, {x: 1500, z: -400, ease: "power1.inOut"}, 'toLeft')
            .to(this.camera.rotation, {y: Math.PI / 3, ease: "power1.inOut"}, 'toLeft')
            .to(this.canvas, { autoAlpha: 0.6}, 'toLeft')
        )

        let tlCenter = gsap.timeline({
            scrollTrigger: {
                id: 'toCenter',
                trigger: centerTrigger,
                start: "top bottom",
                end: "center-=20% bottom",
                preventOverlaps: true,
            }
        })

        tlCenter.to(this.camera.position, {x: 0, z: 2127, duration: 0.7,ease: "power1.inOut"}, 'toCenter')
        .to(this.camera.rotation, {y: 0, duration: 0.7, ease: "power1.inOut"}, 'toCenter')
        .to(this.camera.position, {x: 0, z: 1500, duration: 0.5,ease: "power1.inOut"}, 'fadeOut')
        .to(this.canvas, { autoAlpha: 0.2, duration: 0.5,ease: "power1.inOut"}, 'fadeOut')




    }

    _noScroll(e)
    {
        e.preventDefault()
    }

    _backScroll(e)
    {
        window.scrollTo(0, 0)
    }

    resize()
    {
        this.camera.aspect = this.config.width / this.config.height
        this.camera.updateProjectionMatrix()
    }

    update(delta)
    {
        if(this.mainBall && this.subBall && this.thirdBall)
        {
            this.mainBall.rotation.y += 0.002
            this.subBall.rotation.y += 0.0005
            this.thirdBall.rotation.y += 0.002
        }
        if(this.camera)
        {
            this.renderer.instance.render(this.scene, this.camera)
            this.camera.updateProjectionMatrix()
        }

        if(this.animationAction)
        {
            this.animationMixer.update(delta * 0.001)
        }
    }
    destroy()
    {
    }
}
import * as THREE from 'three'
import Experience from './Experience.js'

import MainSphere from './MainSphere.js'

export default class World
{
    constructor(_options)
    {
        this.experience = new Experience()
        this.config = this.experience.config
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        
        this.resources.on('groupEnd', (_group) =>
        {
            if(_group.name === 'base')
            {
                this.setMainSphere()
            }
        })
    }

    setMainSphere()
    {
        this.mainSphere = new MainSphere()
    }

    resize()
    {
        if(this.mainSphere)
        {
            this.mainSphere.resize()
        }
    }

    update(delta)
    {
        if(this.mainSphere)
        {
            this.mainSphere.update(delta)
        }
    }

    destroy()
    {
    }
}